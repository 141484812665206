import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '../Shared/Button';
import './index.scss';
import Loader from '../../components/Shared/Loader';
import { downloadAssets } from '../../services/CampaignInternalService';
import { Link } from 'react-router-dom';

const InstuctionList = (props: any) => {
  const [showLoader, setShowLoader] = useState(false);
  const selectedPortfolioBrand = JSON.parse(sessionStorage.getItem('portfolioBrand') || 'null');
  const partnerDetail = JSON.parse(sessionStorage.getItem('partnerDetail') || '{}');

  const partnerEntity = partnerDetail?.partnerEntity || [];
  const partnerEntityFiltered = partnerEntity.find(
    (brand) => brand.brandId === selectedPortfolioBrand?.value
  );

  const categoryId = partnerEntityFiltered?.categoryId || '';
  const businessModelId = partnerEntityFiltered?.businessModelId || '';
  const portfolioBrand = selectedPortfolioBrand?.value || '';

  const brandPageUrl = `/article/hm/learn/brandpage/brand-page2/?categoryId=${categoryId}&businessModelId=${businessModelId}&pageType=LearnPage&portfolioBrand=${portfolioBrand}`;


  const downloadGuidelines = () => {
    setShowLoader(true);
    let fileName: string = 'UploadCampaignRequirements.zip';
    downloadAssets(fileName)
      .then(function (data) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  return (
    <Container fluid="xl">
      <div className="InstructionList">
        <Row>
          <Col xs={12} lg={5} className="d-flex flex-column">
            <h2 className="Heading">How it works</h2>
            <Button
              className="Button Button--transparentBlack mt-auto btn-sm mb-5"
              style={{ width: '200px' }}
              onClick={props.handleCampaign}
            >
              Upload now
            </Button>
          </Col>
          <Col xs={12} lg={7}>
            <ul className="InstructionList-list">
              <li className="InstructionList-listItem" data-testid="instructionListItem">
                <h5 className="Heading">01 - Make sure your assets meet our requirements</h5>
                <p>
                  Before uploading images and assets, make sure they meet H&M’s image requirements:{' '}
                  <a href={brandPageUrl}>
                    Brand Page Guidelines.
                  </a>
                  <ul className="mt-3">
                    <li>Supported file types: jpeg, png, svg</li>
                    <li>Accepted asset ratios: 16:9, 4:5</li>
                  </ul>
                </p>
                {/* <p>
                  Explanation of our beauty requirements can be found{' '}
                  <a onClick={downloadGuidelines} data-testid="here">
                    <i className="icon-Download"></i> here
                  </a>
                  .
                </p>
                <p>
                  Explanation of our fashion/home brand page requirements can be found{' '}
                  <Link to="/guidelines/Brand_Page_guide.pdf" target="_blank" download>
                    <i className="icon-Download"></i> here
                  </Link>
                  .
                </p> */}
              </li>
              <li className="InstructionList-listItem" data-testid="instructionListItem">
                <h5 className="Heading">02 - Upload images by clicking “Upload Now”</h5>
                <p>
                  Upload your images and assets by clicking on “Upload Now.” Keep in mind that you can only upload one
                  asset at a time.{' '}
                </p>
              </li>
              <li className="InstructionList-listItem" data-testid="instructionListItem">
                <h5 className="Heading">03 - Approval</h5>
                <p>
                  After uploading an asset, you’ll see it in the tab, “Pending Approval”. If it’s approved, it’ll move
                  to the tab “Approved.” If it is rejected, it’ll move to the tab “Rejected” where you can see the
                  reason for the rejection. After making the necessary changes, re-upload the image.{' '}
                </p>
              </li>
              {/* <li className="InstructionList-listItem" data-testid="instructionListItem">
                <h5 className="Heading">04 - Go live!</h5>
                <p>
                  When assets are uploaded and your commercial discussion with H&M is complete, your assets will go-live
                  on site!{' '}
                </p>
              </li> */}
            </ul>

            {/* <div className="InstructionList-cta">
              <h5 className="Heading">Asset transfer tool</h5>
              <Button className="Button Button--transparentBlack" onClick={props.handleCampaign}>
                Upload now
              </Button>
            </div> */}
          </Col>
        </Row>
      </div>
      {showLoader ? <Loader /> : <></>}
    </Container>
  );
};

export default InstuctionList;
