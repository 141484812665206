import React from 'react';
import { components } from 'react-select';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import removeIcon from '../assets/images/icons/removeIcon.png';
import arrowIcon from '../assets/images/icons/arrowIcon.png';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

export const dateFormat = (date: any) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;
  var strTime = hours + ':' + minutes + ':' + seconds + ' ' + ampm;

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const formattedDate = date.getDate() + ' ' + monthNames[date.getMonth()] + ', ' + date.getFullYear() + ' ' + strTime;
  return formattedDate;
};

export const getUniqueEntityList = (): any => {
  let entityList: any = [];
  const portfolioBrand = JSON.parse(window.sessionStorage.getItem('portfolioBrand')!);
  const partnerDetail = JSON.parse(window.sessionStorage.getItem('partnerDetail') || '{}');
  const partnerEntity = partnerDetail?.partnerEntity || [];
  const partnerEntityFiltered = partnerEntity.filter(
    (brand) =>
      brand.brandId === portfolioBrand?.value &&
      !(brand.categoryId === '10002' && brand.businessModelId === '10001') &&
      !(brand.categoryId === '10003' && brand.businessModelId === '10001'),
  );

  for (let entity of partnerEntityFiltered) {
    let entityData: any = {
      value: entity.categoryId + ':' + entity.businessModelId,
      label: entity.businessModel + ' - ' + entity.category,
    };
    entityList.push(entityData);
  }
  return entityList;
};

export const getEntityListForLearn = (): any => {
  let entityList: any = [];
  const portfolioBrand = JSON.parse(window.sessionStorage.getItem('portfolioBrand')!);

  const partnerDetail = JSON.parse(window.sessionStorage.getItem('partnerDetail') || '{}');
  const partnerEntity = partnerDetail?.partnerEntity || [];

  const partnerEntityFiltered = partnerEntity.filter((brand) => brand.brandId === portfolioBrand?.value);

  //const uniquePartnerEntity = partnerEntity?.filter((item, index) => partnerEntity?.findIndex(data => data?.['businessModelId'] === item?.['businessModelId']) === index);
  for (let entity of partnerEntityFiltered) {
    let entityData: any = {
      value: entity.categoryId + ':' + entity.businessModelId,
      label: entity.businessModel + ' - ' + entity.category,
    };
    entityList.push(entityData);
  }

  if (window.sessionStorage.getItem('userType') === 'HmUser') {
    entityList = [];
    entityList.push({ value: '10001:10002', label: 'Dropship - Fashion' });
    entityList.push({ value: '10002:10002', label: 'Dropship - Home' });
    entityList.push({ value: '10004:10002', label: 'Dropship  - Kids' });
    entityList.push({ value: '10001:10001', label: 'Wholesale - Fashion' });
    entityList.push({ value: '10002:10001', label: 'Wholesale - Home' });
    entityList.push({ value: '10003:10001', label: 'Wholesale - Beauty' });
    entityList.push({ value: '10004:10001', label: 'Wholesale - Kids' });
  }

  return entityList;
};

export const getUniqueArray = (data: any, value: any) => {
  let dataSet: any = [];
  let uniqueData: any = [];
  for (let item of data) {
    dataSet.push(item?.[value]);
  }
  const uniqueFilter = dataSet.filter(function (item: any, i: any, ar: any) {
    return ar.indexOf(item) === i;
  });
  for (let item of uniqueFilter) {
    let modifiedData: any = { value: item, label: item };
    if (item) {
      uniqueData.push(modifiedData);
    }
  }
  return uniqueData;
};

export const exportToCSVInvoiceLineItems = (invoiceLineItems, invoiceId) => {
  const exportFileName = `LineItems-${invoiceId}-${Date.now()}${fileExtension}`;

  const formattedData = invoiceLineItems.map((item) => ({
    'Order ID': item.hybrisOrderNumber,
    'Article Name': item.articleDescription,
    Quantity: item.quantity,
    GTIN: item?.gtin,
    'Product SKU': item?.productSku,
    'Supplier Product SKU': item.supplierProductSku,
    'Offer Description': item?.offerDescription,
    'Offer SKU': item?.offerSku,
    'Net Amount': formatNumber(item?.price?.netAmount),
    'VAT Amount': formatNumber(item?.price?.vatAmount),
    'VAT Rate': item?.price?.vatRate + '%',
    Amount: formatNumber(item?.price?.grossAmount),
  }));

  const ws = XLSX.utils.json_to_sheet(formattedData);

  const columnWidths = formattedData.reduce((acc, row) => {
    Object.keys(row).forEach((key) => {
      const contentWidth = String(row[key]).length;
      const minWidth = 10;
      acc[key] = Math.max(acc[key] || 0, contentWidth, minWidth);
    });
    return acc;
  }, {});

  ws['!cols'] = Object.keys(columnWidths).map((key) => ({
    wch: columnWidths[key] + 2,
  }));

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Line Items');

  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

  const data = new Blob([excelBuffer], { type: fileType });

  FileSaver.saveAs(data, exportFileName);
};

export const getCurrencySymbol = (currencyCode) => {
  const currencySymbols = {
    USD: '$',
    EUR: '€',
    GBP: '£',
    SEK: 'kr',
    PLN: 'zł',
    DKK: 'kr',
  };
  return currencySymbols[currencyCode] || currencyCode;
};

export function formatNumber(number) {
  if (number === 0 || number === '' || number === null || number === undefined) {
    return '0.00';
  }

  return number
    .toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replace(/,/g, ' ');
}

export const showErrorForDuration = (setShowFailed, duration) => {
  setShowFailed(true); // Immediately set to true
  setTimeout(() => {
    setShowFailed(false); // Set to false after the duration
  }, duration);
};

export const getUniquePortfolioBrands = (): any => {
  let entityList: any = [];
  const partnerEntity = JSON.parse(window.sessionStorage.getItem('partnerDetail')!)?.partnerEntity
    ? JSON.parse(window.sessionStorage.getItem('partnerDetail')!)?.partnerEntity
    : [];

  const uniquePartnerEntity = partnerEntity?.filter(
    (item, index) => partnerEntity?.findIndex((data) => data?.['brand'] === item?.['brand']) === index,
  );
  for (let entity of uniquePartnerEntity) {
    let entityData: any = { value: entity?.brandId, label: entity?.brand };
    entityList.push(entityData);
  }

  return entityList?.sort((a, b) => a?.label?.localeCompare(b?.label, 'sv'));
};

export const customStyles = {
  // menu: (provided) => ({
  //   ...provided,
  //   maxHeight: 200,
  //   overflowY: 'hidden',
  // }),
  // menuList: (provided) => ({
  //   ...provided,
  //   padding: 0,
  //   overflowY: 'auto',
  //   maxHeight: 200,
  // }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transition: 'transform 0.3s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};

export const CustomClearIndicator = (props) => (
  <components.ClearIndicator {...props}>
    <img src={removeIcon} alt="Clear" style={{ cursor: 'pointer' }} />
  </components.ClearIndicator>
);

export const CustomDropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <img src={arrowIcon} alt="Dropdown" style={{ cursor: 'pointer' }} />
  </components.DropdownIndicator>
);

export const quickLinksDataOptions = [
  {
    portfolioBrand: '1',
    categoryId: '10001',
    businessModelId: '10001',
    data: [
      {
        heading: 'Marketing Data Guide',
        url: '/article/hm/learn/products-wholesale/product-masterdata-guide-fashion/?categoryId=10001&businessModelId=10001&pageType=LearnPage&portfolioBrand=1',
      },
      {
        heading: 'Brand Page Info',
        url: '/article/hm/learn/brandpage/brand-page/?categoryId=10001&businessModelId=10001&pageType=LearnPage&portfolioBrand=1',
      },
      {
        heading: 'Logistic Guideline',
        url: '/article/hm/learn/logistic-guideline/booking--delivery22/?categoryId=10001&businessModelId=10001&pageType=LearnPage&portfolioBrand=1',
      },
      {
        heading: 'WAREHOUSE ADDRESSES',
        url: '/contact',
      },
    ],
  },
  {
    portfolioBrand: '1',
    categoryId: '10002',
    businessModelId: '10001',
    data: [
      {
        heading: 'FAQ',
        url: '/article/hm/learn/faq-wholesale/chemical-restrictions/?categoryId=10002&businessModelId=10001&pageType=LearnPage&portfolioBrand=1',
      },
      {
        heading: 'Brand Page Info',
        url: '/article/hm/learn/brandpage/brand-page/?categoryId=10002&businessModelId=10001&pageType=LearnPage&portfolioBrand=1',
      },
      {
        heading: 'WAREHOUSE ADDRESSES',
        url: '/contact',
      },
    ],
  },
  {
    portfolioBrand: '1',
    categoryId: '10003',
    businessModelId: '10001',
    data: [
      {
        heading: 'Marketing Data Guide',
        url: '/article/hm/learn/products-wholesale/product-masterdata-guide-beauty/?categoryId=10003&businessModelId=10001&pageType=LearnPage&portfolioBrand=1',
      },
      {
        heading: 'Brand Page Info',
        url: '/article/hm/learn/brandpage/brand-page/?categoryId=10003&businessModelId=10001&pageType=LearnPage&portfolioBrand=1',
      },
      {
        heading: 'Logistic Guideline',
        url: '/article/hm/learn/logistic-guideline/booking-delivery-beauty/?categoryId=10003&businessModelId=10001&pageType=LearnPage&portfolioBrand=1',
      },
      {
        heading: 'WAREHOUSE ADDRESSES',
        url: '/contact',
      },
    ],
  },
  {
    portfolioBrand: '1',
    categoryId: '10004',
    businessModelId: '10001',
    data: [
      {
        heading: 'Marketing Data Guide',
        url: '/article/hm/learn/products-wholesale/product-masterdata-guide-fashion/?categoryId=10004&businessModelId=10001&pageType=LearnPage&portfolioBrand=1',
      },
      {
        heading: 'Brand Page Info',
        url: '/article/hm/learn/brandpage/brand-page/?categoryId=10004&businessModelId=10001&pageType=LearnPage&portfolioBrand=1',
      },
      {
        heading: 'Logistic Guideline',
        url: '/article/hm/learn/logistic-guideline/booking--delivery22/?categoryId=10004&businessModelId=10001&pageType=LearnPage&portfolioBrand=1',
      },
      {
        heading: 'WAREHOUSE ADDRESSES',
        url: '/contact',
      },
    ],
  },
  {
    portfolioBrand: '1',
    categoryId: '10001',
    businessModelId: '10002',
    data: [
      {
        heading: 'Product information guide',
        url: '/article/hm/learn/creating-products-dropship/product-information-clothing/?categoryId=10001&businessModelId=10002&pageType=LearnPage&portfolioBrand=1',
      },
      {
        heading: 'INVOICE H&M',
        url: '/article/hm/learn/invoicing-dropship/invoicing-guide/?categoryId=10001&businessModelId=10002&pageType=LearnPage&portfolioBrand=1',
      },
      {
        heading: 'Contact Us',
        url: '/article/hm/learn/contact-us/contact-us/?categoryId=10001&businessModelId=10002&pageType=LearnPage&portfolioBrand=1',
      },
      {
        heading: 'FAQ',
        url: '/article/hm/learn/faq-dropship/customer-care/?categoryId=10001&businessModelId=10002&pageType=LearnPage&portfolioBrand=1',
      },
    ],
  },
  {
    portfolioBrand: '1',
    categoryId: '10002',
    businessModelId: '10002',
    data: [
      {
        heading: 'Product information guide',
        url: '/article/hm/learn/creating-products-dropship/product-information-homeware/?categoryId=10002&businessModelId=10002&pageType=LearnPage&portfolioBrand=1',
      },
      {
        heading: 'INVOICE H&M',
        url: '/article/hm/learn/invoicing-dropship/invoicing-guide/?categoryId=10002&businessModelId=10002&pageType=LearnPage&portfolioBrand=1',
      },
      {
        heading: 'Contact Us',
        url: '/article/hm/learn/contact-us/contact-us/?categoryId=10002&businessModelId=10002&pageType=LearnPage&portfolioBrand=1',
      },
      {
        heading: 'FAQ',
        url: '/article/hm/learn/faq-dropship/customer-care/?categoryId=10002&businessModelId=10002&pageType=LearnPage&portfolioBrand=1',
      },
    ],
  },
  {
    portfolioBrand: '1',
    categoryId: '10003',
    businessModelId: '10002',
    data: [
      {
        heading: 'FAQ',
        url: '/article/hm/learn/faq-dropship/customer-care/?categoryId=10003&businessModelId=10002&pageType=LearnPage&portfolioBrand=1',
      },
    ],
  },
  {
    portfolioBrand: '1',
    categoryId: '10004',
    businessModelId: '10002',
    data: [
      {
        heading: 'Product information guide',
        url: '/article/hm/learn/creating-products-dropship/product-information-homeware/?categoryId=10004&businessModelId=10002&pageType=LearnPage&portfolioBrand=1',
      },
      {
        heading: 'INVOICE H&M',
        url: '/article/hm/learn/invoicing-dropship/invoicing-guide/?categoryId=10004&businessModelId=10002&pageType=LearnPage&portfolioBrand=1',
      },
      {
        heading: 'FAQ',
        url: '/article/hm/learn/faq-dropship/customer-care/?categoryId=10004&businessModelId=10002&pageType=LearnPage&portfolioBrand=1',
      },
    ],
  },
  {
    portfolioBrand: '2',
    categoryId: '10001',
    businessModelId: '10001',
    data: [],
  },
  {
    portfolioBrand: '2',
    categoryId: '10002',
    businessModelId: '10001',
    data: [],
  },
  {
    portfolioBrand: '2',
    categoryId: '10003',
    businessModelId: '10001',
    data: [],
  },
  {
    portfolioBrand: '2',
    categoryId: '10004',
    businessModelId: '10001',
    data: [],
  },
  {
    portfolioBrand: '2',
    categoryId: '10001',
    businessModelId: '10002',
    data: [],
  },
  {
    portfolioBrand: '2',
    categoryId: '10002',
    businessModelId: '10002',
    data: [],
  },
  {
    portfolioBrand: '2',
    categoryId: '10003',
    businessModelId: '10002',
    data: [],
  },
  {
    portfolioBrand: '2',
    categoryId: '10004',
    businessModelId: '10002',
    data: [],
  },
  {
    portfolioBrand: '3',
    categoryId: '10001',
    businessModelId: '10001',
    data: [],
  },
  {
    portfolioBrand: '3',
    categoryId: '10002',
    businessModelId: '10001',
    data: [],
  },
  {
    portfolioBrand: '3',
    categoryId: '10003',
    businessModelId: '10001',
    data: [],
  },
  {
    portfolioBrand: '3',
    categoryId: '10004',
    businessModelId: '10001',
    data: [],
  },
  {
    portfolioBrand: '3',
    categoryId: '10001',
    businessModelId: '10002',
    data: [],
  },
  {
    portfolioBrand: '3',
    categoryId: '10002',
    businessModelId: '10002',
    data: [],
  },
  {
    portfolioBrand: '3',
    categoryId: '10003',
    businessModelId: '10002',
    data: [],
  },
  {
    portfolioBrand: '3',
    categoryId: '10004',
    businessModelId: '10002',
    data: [],
  },
  {
    portfolioBrand: '4',
    categoryId: '10001',
    businessModelId: '10001',
    data: [],
  },
  {
    portfolioBrand: '4',
    categoryId: '10002',
    businessModelId: '10001',
    data: [],
  },
  {
    portfolioBrand: '4',
    categoryId: '10003',
    businessModelId: '10001',
    data: [],
  },
  {
    portfolioBrand: '4',
    categoryId: '10004',
    businessModelId: '10001',
    data: [],
  },
  {
    portfolioBrand: '4',
    categoryId: '10001',
    businessModelId: '10002',
    data: [],
  },
  {
    portfolioBrand: '4',
    categoryId: '10002',
    businessModelId: '10002',
    data: [],
  },
  {
    portfolioBrand: '4',
    categoryId: '10003',
    businessModelId: '10002',
    data: [],
  },
  {
    portfolioBrand: '4',
    categoryId: '10004',
    businessModelId: '10002',
    data: [],
  },
  {
    portfolioBrand: '5',
    categoryId: '10001',
    businessModelId: '10001',
    data: [],
  },
  {
    portfolioBrand: '5',
    categoryId: '10002',
    businessModelId: '10001',
    data: [],
  },
  {
    portfolioBrand: '5',
    categoryId: '10003',
    businessModelId: '10001',
    data: [],
  },
  {
    portfolioBrand: '5',
    categoryId: '10004',
    businessModelId: '10001',
    data: [],
  },
  {
    portfolioBrand: '5',
    categoryId: '10001',
    businessModelId: '10002',
    data: [],
  },
  {
    portfolioBrand: '5',
    categoryId: '10002',
    businessModelId: '10002',
    data: [],
  },
  {
    portfolioBrand: '5',
    categoryId: '10003',
    businessModelId: '10002',
    data: [],
  },
  {
    portfolioBrand: '5',
    categoryId: '10004',
    businessModelId: '10002',
    data: [],
  },
];


export const getUniqueBusinessModel = (): { value: string; label: string }[] => {
  const businessModelList: { value: string; label: string }[] = [];
  const portfolioBrand = JSON.parse(window.sessionStorage.getItem('portfolioBrand')!);

  const partnerDetail = JSON.parse(window.sessionStorage.getItem('partnerDetail') || '{}');
  const partnerEntity = partnerDetail?.partnerEntity || [];

  const partnerEntityFiltered = partnerEntity.filter((brand) => brand.brandId === portfolioBrand?.value);

  const uniqueBusinessModelIds = new Set();

  for (const entity of partnerEntityFiltered) {
    if (!uniqueBusinessModelIds.has(entity.businessModelId)) {
      uniqueBusinessModelIds.add(entity.businessModelId);
      businessModelList.push({
        value: entity.businessModelId,
        label: entity.businessModel,
      });
    }
  }

  if (window.sessionStorage.getItem('userType') === 'HmUser') {
    businessModelList.push({ value: '10001', label: 'Wholesale' });
    businessModelList.push({ value: '10002', label: 'Dropship' });
  }

  return businessModelList;
};