import { useState, useCallback, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Login from './pages/Login';
import Welcome from './pages/Welcome';
import Dashboard from './pages/Dashboard';
import PartnerProfile from './pages/PartnerProfile';
import HmUser from './pages/HmUser';
import PartnerUser from './pages/PartnerUser';
import PartnerAccount from './pages/PartnerAccount';
import Campaigns from './pages/Campaigns';
import UploadCampaign from './pages/UploadCampaign';
import ApproveCampaign from './pages/ApproveCampaign';
import Learn from './pages/Learn';
import Article from './pages/Article';
import Insights from './pages/Insights';
import SharedComponent from './pages/SharedComponents';
import Onboarding from './pages/Onboarding';
import OnboardingCompleted from './pages/OnboardingCompleted';
import ProtectedRoute from './components/ProtectedRoute';
import Navigation from './components/Navigation';
import Contact from './pages/Contact';
import CompanyInformation from './pages/CompanyInformation';
import PresentationMode from './pages/PresentationMode';
import Sizes from './pages/Sizes';
import ImportMethods from './pages/Sizes/ImportMethods';
import ImportExcell from './pages/Sizes/ImportMethods/Excell';
import ImportManually from './pages/Sizes/ImportMethods/Manually';
import SizesTable from './pages/Sizes/SizesTable';
import Support from './pages/Support';
import NewTicket from './pages/Support/NewTicket';
import TicketConversation from './pages/Support/TicketConversation';
import ImportManuallyEdit from './pages/Sizes/ImportMethods/ManuallyEdit/index';
import getTheme from './helpers/themeManager';
import DropShipInvoice from './pages/DropShipInvoice';
import DisplayLineItems from './pages/DropShipInvoice/DisplayLineItems';
import InvoiceListing from './pages/DropShipInvoice/InvoiceListing';

import './App.scss';
import './styles/base.scss';
import './components/Global/Headings/index.scss';
import './components/Global/Utils/utils.scss';
import './components/Global/Icons/index.scss';
import './components/Global/Page/index.scss';
import './components/Global/Section/index.scss';
import './components/Global/Title/index.scss';
import './components/Global/Page/index.scss';
import './components/Global/Themes/index.scss';
import './components/Global/Article/index.scss';

const DefaultContainer = () => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(false);
  const [isGetTheme, setGetTheme] = useState('');
  const setNavCollapsed = useCallback(
    (event) => {
      setIsNavCollapsed(!isNavCollapsed);
    },
    [isNavCollapsed],
  );
  const userType = window.sessionStorage?.getItem('userType');
  let location = useLocation();
  const windowUrl = location.search;
  const params = new URLSearchParams(windowUrl);

  useEffect(() => {
    setGetTheme(getTheme());
  }, [userType]);

  let hideLeftNav = params.has('from') || params.has('link') ? false : true;

  return (
    <>
      {hideLeftNav && <Navigation setNavCollapsed={setNavCollapsed} />}
      <div
        className={`app-content ${isGetTheme} ${isNavCollapsed ? 'small-padding' : ''} ${hideLeftNav ? '' : 'no-padding'
          }`}
      >
        <ProtectedRoute path="/home" component={Dashboard} />
        <ProtectedRoute path="/partner-profile" component={PartnerProfile} />
        <ProtectedRoute path="/partner-user" component={PartnerUser} />
        <ProtectedRoute path="/partner-account" component={PartnerAccount} />
        <ProtectedRoute path="/hm-user" component={HmUser} />
        <ProtectedRoute exact path="/assets" component={Campaigns} />
        <ProtectedRoute exact path="/assets/upload-asset" component={UploadCampaign} />
        <ProtectedRoute path="/approve-asset" component={ApproveCampaign} />
        <ProtectedRoute exact
          path="/article/:portfolioBrand/:category/:categoryname"
          component={Article}
          hideLeftNav={hideLeftNav}
        />
        <ProtectedRoute exact
          path="/article/:portfolioBrand/:category/:categoryname/:articlename"
          component={Article}
          hideLeftNav={hideLeftNav}
        />
        <ProtectedRoute path="/learn" component={Learn} />
        <ProtectedRoute path="/sustainability" component={Learn} />
        <ProtectedRoute path="/insights" component={Insights} />
        <ProtectedRoute path="/contact" component={Contact} />
        <ProtectedRoute path="/contacts-dropship" component={Contact} />
        <ProtectedRoute path="/company-information" component={CompanyInformation} />
        <ProtectedRoute path="/components" component={SharedComponent} />
        <ProtectedRoute path="/presentation-mode" component={PresentationMode} />
        <ProtectedRoute exact path="/sizes" component={Sizes} />
        <ProtectedRoute exact path="/sizes/import/method" component={ImportMethods} />
        <ProtectedRoute exact path="/sizes/importmethod/excell" component={ImportExcell} />
        <ProtectedRoute exact path="/sizes/importmethod/manually" component={ImportManually} />
        <ProtectedRoute exact path="/sizes/importmethod/sizestable" component={SizesTable} />
        <ProtectedRoute exact path="/sizes/:id" component={ImportManuallyEdit} />
        <ProtectedRoute exact path="/invoice-listing" component={InvoiceListing} />
        <ProtectedRoute exact path="/invoice-listing/invoice" component={DropShipInvoice} />
        <ProtectedRoute exact path="/invoice-listing/invoice/display-line-items" component={DisplayLineItems} />

        {/* <ProtectedRoute exact path="/support" component={Support} />
                <ProtectedRoute exact path="/support/newticket" component={NewTicket} />
                <ProtectedRoute exact path="/support/ticketconversation/:caseId" component={TicketConversation} /> */}
      </div>
    </>
  );
};

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <ProtectedRoute path="/welcome" component={Welcome} />
        <ProtectedRoute path="/onboarding" component={Onboarding} theme={getTheme()} />
        <ProtectedRoute path="/onboarding-completed" component={OnboardingCompleted} />
        <Route component={DefaultContainer} />
        {/* <UserContextProvider> </UserContextProvider> */}
      </Switch>
    </Router>
  );
};

export default App;
