import { useState, useEffect } from 'react';
import './index.scss';
import { Container } from 'react-bootstrap';
import { getPage } from '../../services/CmsService';
import { useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Block from '../../components/Block';
import Loader from '../../components/Shared/Loader';
import Hero from '../../components/Hero';
import Select from 'react-select';
import { getUniqueBusinessModel } from '../../helpers/utilities';

const Contact = (props: any) => {
  let path = window.location.pathname;
  const [showLoader, setShowLoader] = useState(false);
  const [businessModelList, setBusinessModelList] = useState<any[]>([]);
  const [selectedBusinessModel, setSelectedBusinessModel] = useState<any[]>([]);
  const [showViaDc, setShowViaDc] = useState(false);
  const [showDropShip, setShowDropShip] = useState(false);

  interface Page {
    heading: string;
    name: string;
    preamble: string;
    tableOfContents: any;
    mainContentFriendly: any;
  }

  const history = useHistory();

  const [pageDataViaDc, setPageDataViaDc] = useState<Page>();
  const [pageDataDropShip, setPageDataDropShip] = useState<Page>();

  const portfolioBrand = JSON.parse(window.sessionStorage.getItem('portfolioBrand')!);

  useEffect(() => {
    if (showViaDc) {
      if (portfolioBrand?.value === '2') {
        getPageDataViaDc('arket/contacts-wholesale');
      } else if (portfolioBrand?.value === '3') {
        getPageDataViaDc('other-stories/contacts-wholesale');
      } else if (portfolioBrand?.value === '4') {
        getPageDataDropShip('monki/contacts-wholesale');
      } else if (portfolioBrand?.value === '5') {
        getPageDataDropShip('weekday/contacts-wholesale');
      } else {
        getPageDataViaDc('hm/contacts-wholesale');
      }
    }
    if (showDropShip) {
      if (portfolioBrand?.value === '2') {
        getPageDataDropShip('arket/contacts-dropship');
      } else if (portfolioBrand?.value === '3') {
        getPageDataDropShip('other-stories/contacts-dropship');
      } else if (portfolioBrand?.value === '4') {
        getPageDataDropShip('monki/contacts-dropship');
      } else if (portfolioBrand?.value === '5') {
        getPageDataDropShip('weekday/contacts-dropship');
      } else {
        getPageDataDropShip('hm/contacts-dropship');
      }
    }
  }, [showViaDc, showDropShip]);

  const getPageDataViaDc = (url: string) => {
    setShowLoader(true);
    getPage(url)
      .then(function (data) {
        if (data) {
          setPageDataViaDc(data[0]);
        }
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  const getPageDataDropShip = (url: string) => {
    setShowLoader(true);
    getPage(url)
      .then(function (data) {
        if (data) {
          setPageDataDropShip(data[0]);
        }
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  const getBusinessModel = (): any => {
    const businessModel: any = getUniqueBusinessModel();
    setBusinessModelList(businessModel);
    if (businessModel?.length === 1) {
      handleBusinessModel(businessModel?.[0]);
    }
  };

  const handleBusinessModel = (selectedOption: any) => {
    setSelectedBusinessModel(selectedOption);
    if (selectedOption.value === '10001') {
      setShowViaDc(true);
      setShowDropShip(false);
    } else if (selectedOption.value === '10002') {
      setShowViaDc(false);
      setShowDropShip(true);
    }
  };

  useEffect(() => {
    getBusinessModel();
  }, []);

  return (
    <div className="" data-testid="contact-component">
      <div>
        <Hero className="Hero Hero-gradient noHeroImage">
          <h1 className="mt-4 Heading">
            Contact Us
          </h1>
        </Hero>
        <Container fluid="xl">
          {businessModelList?.length > 1 && (<Row>
            <Col lg={4}><Select
              isSearchable
              value={selectedBusinessModel}
              onChange={handleBusinessModel}
              options={businessModelList}
              placeholder="View as..."
              className="mt-5"
            /></Col>
          </Row>)}


          <Row>
            <Col xs={12} sm={12} md={12}>
              <div className="Profile-hero">
                <p className="mt-4 Heading">
                  {showViaDc && pageDataViaDc?.preamble
                    ? pageDataViaDc.preamble
                    : showDropShip && pageDataDropShip?.preamble
                      ? pageDataDropShip.preamble
                      : (showViaDc || showDropShip) && 'Here you can find contact details for people who can support you.'}
                </p>
                <div className="Article">
                  {showViaDc &&
                    pageDataViaDc &&
                    pageDataViaDc.mainContentFriendly &&
                    pageDataViaDc?.mainContentFriendly.map((blockListItem: any, index: number) => {
                      return <Block position={index} block={blockListItem}></Block>;
                    })}

                  {showDropShip &&
                    pageDataDropShip &&
                    pageDataDropShip.mainContentFriendly &&
                    pageDataDropShip?.mainContentFriendly.map((blockListItem: any, index: number) => {
                      return <Block position={index} block={blockListItem}></Block>;
                    })}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {showLoader ? <Loader /> : <></>}
    </div>
  );
};

export default Contact;